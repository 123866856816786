// SidePanel.js
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./SidePanel.css";
import SettingsDialog from "../SettingsDialog";

const SidePanel = ({
    isOpen,
    togglePanel,
    navigationItems = [],
    children,
    menuButtonRef,
    className = "",
    preferences,
    setPreferences,
    disableOutsideClick = false, // New prop added
}) => {
    const navigate = useNavigate();
    const dialogRefSettings = useRef(null);
    const openSettingsDialog = () => {
        dialogRefSettings.current.open = true;
    };

    // Handle clicks outside the side panel to close it
    useEffect(() => {
        if (disableOutsideClick) {
            return; // Do not add the event listener if disabled
        }

        const handleClickOutside = (event) => {
            if (
                event.target.classList.contains("side-panel") ||
                event.target.closest(".side-panel") ||
                (menuButtonRef && menuButtonRef.current.contains(event.target))
            ) {
                return;
            }
            if (isOpen) {
                togglePanel();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, menuButtonRef, togglePanel, disableOutsideClick]); // Added disableOutsideClick to dependencies

    return (
        <div className={`side-panel ${isOpen ? "open" : ""} ${className}`}>
            <div className="side-panel-selection">
                <div className="side-panel-close-button">
                    <md-icon-button onClick={togglePanel}>
                        <md-icon>close</md-icon>
                    </md-icon-button>
                </div>
                <ul className="scrollable">
                    <h3>Pages</h3>
                    {navigationItems.map((item) => (
                        <li key={item.label} onClick={() => navigate(item.path)}>
                            {item.label}
                        </li>
                    ))}
                    {children}
                </ul>
            </div>
            {preferences && (
                <>
                    <div className="settings-button">
                        <li onClick={openSettingsDialog}>
                            <md-icon>settings</md-icon>
                            Settings
                        </li>
                    </div>
                    <SettingsDialog
                        dialogRefSettings={dialogRefSettings}
                    />
                </>
            )}
        </div>
    );
};

export default React.memo(SidePanel);
