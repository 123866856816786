// WordBankPage.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./WordBankPage.css";
import "../../App.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import SidePanel from "../../components/SidePanel/SidePanel.js";
import WordBankSidePanelContent from "./WordBankSidePanelContent.js"; // New import

axios.defaults.withCredentials = true;

function WordBankPage() {
    const [theme, setTheme] = useState("dark");
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [selectedView, setSelectedView] = useState({
        dataKey: "notMasteredWordData",
        dataTitle: "Words to Learn",
    });
    const [wordData, setWordData] = useState([]);
    const [notMasteredWordData, setNotMasteredWordData] = useState([]);
    const [masteredWordData, setMasteredWordData] = useState([]);

    const [isPopupMenuVisible, setIsPopupMenuVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const menuButtonRef = useRef(null);

    const navigate = useNavigate();

    const togglePanel = useCallback(() => {
        setIsPanelOpen((prevIsOpen) => !prevIsOpen);
    }, []);

    useEffect(() => {
        if (theme) {
            import(`../../themes/${theme}.css`)
                .then(() => {})
                .catch((err) => {
                    console.error(`Failed to load ${theme} theme`, err);
                });
            document.body.className = theme;
        }
    }, [theme]);

    let wordList = wordData;
    switch (selectedView.dataKey) {
        case "wordData":
            wordList = wordData;
            break;
        case "notMasteredWordData":
            wordList = notMasteredWordData;
            break;
        case "masteredWordData":
            wordList = masteredWordData;
            break;
        default:
            wordList = [];
    }

    const fetchMorphemes = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-morphemes`,
                {
                    withCredentials: true,
                }
            );

            const { morphemes, masteryThreshold } = response.data;

            // Sort the data by trueCount in descending order
            const sortedData = morphemes.sort((a, b) => b.trueCount - a.trueCount);
            setWordData(sortedData);

            // Filter the mastered morphemes and update the state
            const mastered = sortedData.filter(
                (morpheme) => morpheme.effectiveCount >= masteryThreshold
            );
            setMasteredWordData(mastered);

            // Filter the not mastered morphemes and update the state
            const notMastered = sortedData.filter(
                (morpheme) => morpheme.effectiveCount < masteryThreshold
            );
            setNotMasteredWordData(notMastered);

            return sortedData;
        } catch (error) {
            console.error("Error fetching morphemes:", error);
            console.error("Error details:", error.response);
            throw error;
        }
    };

    const updateMorphemesStatus = async (selectedRows, endpoint) => {
        try {
            const morphemeIds = selectedRows.map((row) => row._id);

            await axios.patch(
                `${process.env.REACT_APP_API_URL}/${endpoint}`,
                { morphemeIds },
                {
                    withCredentials: true,
                }
            );

            // Fetch and update the morphemes data
            await fetchMorphemes();
            setSelectedRows([]);
        } catch (error) {
            console.error("Error updating morphemes:", error);
            throw error;
        }
    };

    const updateMorphemesToMastered = (selectedRows) =>
        updateMorphemesStatus(selectedRows, "update-morphemes-as-mastered");

    const updateMorphemesToNotMastered = (selectedRows) =>
        updateMorphemesStatus(selectedRows, "update-morphemes-as-not-mastered");

    useEffect(() => {
        fetchMorphemes();
    }, []);

    const handleRowClick = (row) => {
        setSelectedRows((prevSelectedRows) => {
            const isRowAlreadySelected = prevSelectedRows.some(
                (selectedRow) => selectedRow._id === row._id
            );
            if (isRowAlreadySelected) {
                return prevSelectedRows.filter((selectedRow) => selectedRow._id !== row._id);
            } else {
                return [...prevSelectedRows, row];
            }
        });
    };

    const isRowSelected = (row) => {
        return selectedRows.some((selectedRow) => selectedRow._id === row._id);
    };

    useEffect(() => {
        if (selectedRows.length > 0) {
            setIsPopupMenuVisible(true);
        } else if (isPopupMenuVisible) {
            triggerCloseAnimation();
        }
    }, [isPopupMenuVisible, selectedRows]);

    const triggerCloseAnimation = () => {
        setIsClosing(true);
    };

    const handleAnimationEnd = () => {
        if (isClosing) {
            setIsPopupMenuVisible(false);
            setIsClosing(false);
        }
    };

    // WordBankPage-specific SidePanel content
    const wordDataTitles = {
        notMasteredWordData: "Words to Learn",
        masteredWordData: "Mastered Words",
        wordData: "All Words",
    };

    return (
        <div className={theme}>
            <div className="app word-bank">
                <div className="app-container">
                    <header className="app-header">
                        <span style={{ marginRight: "auto" }}>
                            <md-icon-button ref={menuButtonRef} onClick={togglePanel}>
                                <md-icon>menu</md-icon>
                            </md-icon-button>
                        </span>
                    </header>
                    <SidePanel
                        isOpen={isPanelOpen}
                        togglePanel={togglePanel}
                        navigationItems={[
                            { label: "Home", path: "/" },
                            { label: "Conversation", path: "/conversation" },
                            { label: "Progress Dashboard", path: "/progressDashboard" },
                        ]}
                        menuButtonRef={menuButtonRef}
                        className="word-bank-side-panel" // Optional: Add context-specific class
                    >
                        <WordBankSidePanelContent
                            selectedView={selectedView}
                            setSelectedView={setSelectedView}
                            wordDataTitles={wordDataTitles}
                        />
                    </SidePanel>
                    <div className="word-bank-area">
                        <span className="word-bank-title">{selectedView.dataTitle}</span>
                        <div className="word-bank-table-container">
                            <div className="word-bank-table">
                                <div className="word-bank-header-row">
                                    <div className="word-bank-header-item">Word</div>
                                    <div className="word-bank-header-item">
                                        Transliteration
                                    </div>
                                    <div className="word-bank-header-item">Translation</div>
                                    <div className="word-bank-header-item">Count</div>
                                </div>
                                {wordList.map((item) => (
                                    <div
                                        key={item._id}
                                        className={`word-bank-row ${
                                            isRowSelected(item) ? "selected" : ""
                                        }`}
                                        onClick={() => handleRowClick(item)}
                                    >
                                        <div className="word-bank-item">
                                            {item.originalMorpheme}
                                        </div>
                                        <div className="word-bank-item">
                                            {item.transliteratedMorpheme}
                                        </div>
                                        <div className="word-bank-item">
                                            {item.translatedMorpheme}
                                        </div>
                                        <div className="word-bank-item">
                                            {item.effectiveCount}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {isPopupMenuVisible && (
                            <div
                                className={`popup-menu ${isClosing ? "close" : ""}`}
                                onAnimationEnd={handleAnimationEnd}
                            >
                                {(selectedView.dataKey === "notMasteredWordData" ||
                                    selectedView.dataKey === "wordData") && (
                                    <md-filled-tonal-button
                                        onClick={() => updateMorphemesToMastered(selectedRows)}
                                    >
                                        Mark as Mastered
                                    </md-filled-tonal-button>
                                )}
                                {(selectedView.dataKey === "masteredWordData" ||
                                    selectedView.dataKey === "wordData") && (
                                    <md-filled-tonal-button
                                        onClick={() =>
                                            updateMorphemesToNotMastered(selectedRows)
                                        }
                                    >
                                        Mark as Not Mastered
                                    </md-filled-tonal-button>
                                )}
                                <md-filled-tonal-button onClick={() => setSelectedRows([])}>
                                    Clear Selection
                                </md-filled-tonal-button>
                                <md-filled-tonal-icon-button onClick={triggerCloseAnimation}>
                                    <md-icon>close</md-icon>
                                </md-filled-tonal-icon-button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WordBankPage;
