import React, { useEffect, useCallback, useMemo, useState } from "react";
import axios from "axios";
import WebSocketService from "../../context/WebSocketService";

import MessageContainer from "./MessageContainer"; // Ensure this is the memoized version

function ConversationArea({
    preferences,
    setPreferences,
    conversationHistory,
    setConversationHistory,
}) {
    const [activeMessageId, setActiveMessageId] = useState(null);
    const [wordBank, setWordBank] = useState([]);
    const [localWordBank, setLocalWordBank] = useState([]);
    const [newMessageIds, setNewMessageIds] = useState([]);
    const [expandedMessageId, setExpandedMessageId] = useState(null);
    const [selectedMorphemes, setSelectedMorphemes] = useState([]);
    // Fetch initial morphemes
    const fetchMorphemes = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-morphemes`,
                {
                    withCredentials: true,
                }
            );
            const sortedData = response.data.morphemes.sort(
                (a, b) => b.trueCount - a.trueCount
            );
            const masteryThreshold = response.data.masteryThreshold;
            setPreferences((prevPreferences) => ({
                ...prevPreferences,
                masteryThreshold,
            }));
            setWordBank(sortedData);
            return sortedData;
        } catch (error) {
            console.error("Error fetching morphemes:", error);
            throw error;
        }
    }, [setPreferences]);

    useEffect(() => {
        fetchMorphemes();
    }, [fetchMorphemes]);

    const addSnapshotToHistory = useCallback(
        (snapshot) => {
            const {
                target: messageId,
                fieldName,
                status,
                statusMessage,
                value,
                sender,
            } = snapshot;
            if (messageId === "scenarioSnapshot") return;
            setConversationHistory((prevHistory) => {
                let updatedHistory = prevHistory;
                const messageExists = updatedHistory.some((msg) => msg.id === messageId);
                if (messageExists) {
                    addDataToMessage();
                } else {
                    removeTemporaryMessage();
                    addNewMessageToHistory();
                }
                const shouldAddTemporaryAssistantMessage =
                    sender === "user" &&
                    fieldName === "targetLanguageMessage" &&
                    status === "done";
                if (shouldAddTemporaryAssistantMessage) addTemporaryAssistantMessage();

                return updatedHistory;

                function addTemporaryAssistantMessage() {
                    if (!updatedHistory.some((msg) => msg.id === "temp-assistant")) {
                        updatedHistory = [
                            ...updatedHistory,
                            {
                                id: "temp-assistant",
                                sender: "assistant",
                                targetLanguageMessage: { status: "connecting" },
                            },
                        ];
                    }
                }

                function addNewMessageToHistory() {
                    updatedHistory = [
                        ...updatedHistory,
                        {
                            id: messageId,
                            sender,
                            [fieldName]: { status, statusMessage, value },
                        },
                    ];
                }

                function addDataToMessage() {
                    updatedHistory = updatedHistory.map((msg) =>
                        msg.id !== messageId
                            ? msg
                            : { ...msg, [fieldName]: { status, statusMessage, value } }
                    );
                }

                function removeTemporaryMessage() {
                    updatedHistory = updatedHistory.filter(
                        (msg) => msg.id !== `temp-${sender}`
                    );
                }
            });
        },
        [setConversationHistory]
    );

    // Listen for incremental updates
    useEffect(() => {
        const handleTargetMessage = (data) => {
            addSnapshotToHistory(data);
        };
        WebSocketService.on("message:target", handleTargetMessage);
        return () => {
            WebSocketService.off("message:target", handleTargetMessage);
        };
    }, [addSnapshotToHistory]);

    // Mark new messages
    useEffect(() => {
        if (!conversationHistory.length) return;
        setNewMessageIds((prevNewIds) => {
            const existingIds = new Set(prevNewIds);
            const allIds = new Set(conversationHistory.map((msg) => msg.id));
            // Keep any previously new IDs that still exist
            const combined = [...existingIds].filter((id) => allIds.has(id));
            // Add newly encountered IDs
            conversationHistory.forEach((msg) => {
                if (!existingIds.has(msg.id)) {
                    combined.push(msg.id);
                }
            });
            return combined;
        });
    }, [conversationHistory]);

    // Decrement localWordBank based on seen morphemes
    useEffect(() => {
        if (!conversationHistory.length) return;

        const updatedWordBank = wordBank.map((word) => ({ ...word }));
        conversationHistory.forEach((msg) => {
            const processed = processMorphemeDetails(msg.morphemeTranslationDetails?.value);
            processed.forEach((morpheme) => {
                const existing = updatedWordBank.find(
                    (w) => w.originalMorpheme === morpheme.originalMorpheme.toLowerCase()
                );
                if (existing) {
                    existing.effectiveCount--;
                }
            });
        });
        setLocalWordBank(updatedWordBank);
    }, [wordBank, conversationHistory]);

    // Helper function to parse morphemeTranslationDetails
    const processMorphemeDetails = (morphemeDetails = {}) =>
        Object.values(morphemeDetails)
            .map(([originalMorpheme, transliteratedMorpheme, translatedMorpheme]) => ({
                originalMorpheme,
                transliteratedMorpheme,
                translatedMorpheme,
            }))
            .filter(
                ({ originalMorpheme, transliteratedMorpheme }) =>
                    originalMorpheme && transliteratedMorpheme
            );

    // Build a formatted version of conversationHistory
    const formattedConversationHistory = useMemo(() => {
        if (!Array.isArray(conversationHistory)) {
            console.error("Invalid conversation history.");
            return [];
        }

        const morphemeList = {};
        const selectedMorphemesSet = new Set(selectedMorphemes);

        const incrementMorphemeCount = (morpheme) => {
            if (morphemeList.hasOwnProperty(morpheme)) {
                morphemeList[morpheme] += 1;
            } else {
                morphemeList[morpheme] = 1;
            }
        };

        const isPunctuation = (str) => /^[\p{P}]+$/u.test(str);

        const getSeenCount = (word) => {
            const normalized = word.toLowerCase().trim();
            const localItem = localWordBank.find((m) => m.originalMorpheme === normalized);
            const localCount = localItem ? localItem.effectiveCount : 0;
            const listCount = morphemeList[normalized] || 0;
            return localCount + listCount;
        };

        const calculateOpacity = (seenCount) => {
            return 1 - Math.min(Math.max(seenCount, 1) / preferences.masteryThreshold, 1);
        };

        const createMorphemeObject = (morpheme, index) => {
            let { originalMorpheme, transliteratedMorpheme, translatedMorpheme } = morpheme;
            if (!originalMorpheme || typeof originalMorpheme !== "string") return null;

            if (isPunctuation(originalMorpheme)) {
                transliteratedMorpheme = "";
                translatedMorpheme = "";
            } else if (!transliteratedMorpheme || typeof transliteratedMorpheme !== "string") {
                return null;
            }
            incrementMorphemeCount(originalMorpheme);

            const seenCount = getSeenCount(originalMorpheme);
            const opacity = calculateOpacity(seenCount);
            if (opacity === 0) {
                translatedMorpheme = "";
            }
            return {
                key: `morpheme-${index}`,
                originalMorpheme,
                transliteratedMorpheme,
                translatedMorpheme,
                opacity,
                isSelected: selectedMorphemesSet.has(originalMorpheme),
            };
        };

        return conversationHistory.map((message) => {
            // Safely handle cases where morphemeTranslationDetails or its value might be undefined
            const processedMorphemes = message.morphemeTranslationDetails?.value
                ? processMorphemeDetails(message.morphemeTranslationDetails.value)
                : [];

            const morphemeArray = processedMorphemes
                .map((morpheme, idx) => createMorphemeObject(morpheme, idx))
                .filter(Boolean);
            return {
                ...message,
                morphemeArray,
            };
        });
    }, [conversationHistory, localWordBank, preferences.masteryThreshold, selectedMorphemes]);

    // Close message options on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            const optionsContainer = document.querySelector(".message-options-container");
            if (!optionsContainer) return;
            if (!optionsContainer.contains(event.target)) {
                setActiveMessageId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Translate entire sentence of active message
    const handleFullSentenceTranslation = useCallback(async () => {
        if (!activeMessageId) return;
        setActiveMessageId(null);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/translate-sentence`, {
                messageId: activeMessageId,
            });
        } catch (error) {
            console.error("Error translating sentence:", error);
        }
    }, [activeMessageId]);

    // Toggle expanded word options
    const handleWordOptions = useCallback((messageId) => {
        setActiveMessageId(null);
        setExpandedMessageId((prev) => (prev === messageId ? null : messageId));
    }, []);

    const renderMessageOptions = useCallback(() => {
        return (
            <div className={`message-options-container ${activeMessageId ? "visible" : ""}`}>
                <md-outlined-button
                    className="button"
                    onClick={handleFullSentenceTranslation}
                    title="Translate Message"
                    aria-label="Translate Message"
                >
                    <md-icon
                        style={{
                            transform: "translateY(11.5%)",
                            fontSize: "1rem",
                        }}
                    >
                        translate
                    </md-icon>
                    Full Sentence Translation
                </md-outlined-button>

                <md-outlined-button
                    className="button"
                    onClick={() => handleWordOptions(activeMessageId)}
                    title="Word Options"
                    aria-label="Word Options"
                >
                    <md-icon
                        style={{
                            transform: "translateY(11.5%)",
                            fontSize: "1rem",
                        }}
                    >
                        build_circle
                    </md-icon>
                    Word Options
                </md-outlined-button>
            </div>
        );
    }, [activeMessageId, handleFullSentenceTranslation, handleWordOptions]);

    return (
        <div className="conversation-area">
            <div className="scroll-wrapper scrollable">
                <div className="message-list">
                    {formattedConversationHistory.map((message) => (
                        <MessageContainer
                            key={message.id}
                            isExpanded={expandedMessageId === message.id}
                            message={message}
                            preferences={preferences}
                            setActiveMessageId={setActiveMessageId}
                            wordBank={wordBank}
                            setWordBank={setWordBank}
                            newMessageIds={newMessageIds}
                            expandedMessageId={expandedMessageId}
                            setExpandedMessageId={setExpandedMessageId}
                            selectedMorphemes={selectedMorphemes}
                            setSelectedMorphemes={setSelectedMorphemes}
                            fetchMorphemes={fetchMorphemes}
                        />
                    ))}
                </div>
            </div>
            {activeMessageId !== null && renderMessageOptions()}
        </div>
    );
}

export default ConversationArea;
