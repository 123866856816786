// SettingsDialog.js
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";

const SettingsDialog = ({ dialogRefSettings }) => {
    const navigate = useNavigate();
    const { preferences, setPreferences, logout } = useContext(GlobalContext);

    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const targetLanguageOptions = ["French", "Japanese", "Korean", "German", "Other"];

    const handleLanguageChange = async (newLanguage) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            language: newLanguage,
        }));
        await updatePreferences({ language: newLanguage });

        // List of languages that use non-Roman scripts
        const nonRomanLanguages = [
            "Japanese",
            "Korean",
            "Armenian",
            "Russian",
            "Persian",
            "Traditional Chinese",
            "Simplified Chinese",
            "Mandarin",
            "Arabic",
        ];

        // Check if the selected language is in the list of non-Roman languages
        if (nonRomanLanguages.includes(newLanguage)) {
            await updatePreferences({ showTransliteration: true });
        }
    };

    const updatePreferences = async (newPreferences) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/update-preferences`,
                newPreferences,
                {
                    withCredentials: true,
                }
            );
            await fetchPreferences(); // Fetch preferences to update UI
        } catch (error) {
            console.error("Error updating preferences:", error);
        }
    };

    const fetchPreferences = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-preferences`
            );
            const prefs = await response.data;
            if (prefs.message === "User authentication required") {
                console.error(
                    prefs.message,
                    "User authentication required for fetchPreferences"
                );
            } else {
                setPreferences({
                    language: prefs.language,
                    showTransliteration: prefs.showTransliteration,
                    theme: prefs.theme,
                    masteryThreshold: prefs.masteryThreshold,
                });
            }
        } catch (error) {
            console.error("Error fetching preferences:", error);
        }
    };

    useEffect(() => {
        fetchPreferences();
        setIsOtherSelected(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectLanguage = (event) => {
        const selectedLanguage = event.target.value;
        // Return early if other is selected
        if (selectedLanguage === "Other") {
            setIsOtherSelected(true);
            return;
        }
        setIsOtherSelected(false);
        handleLanguageChange(selectedLanguage);
    };

    const handleOtherLanguageChange = (event) => {
        const otherLanguage = event.target.value;
        handleLanguageChange(otherLanguage);
    };

    const handleMasteryThresholdChange = (event) => {
        const selectedMasteryThreshold = event.target.value;
        if (!selectedMasteryThreshold) return;
        updatePreferences({ masteryThreshold: selectedMasteryThreshold });
    };

    const handleTransliterationVisibilityChange = (event) => {
        const visibility = event.target.selected;
        updatePreferences({ showTransliteration: visibility });
    };

    const handleThemeChange = (event) => {
        const isSelected = event.target.selected;
        const selectedTheme = !isSelected ? "light" : "dark";
        updatePreferences({ theme: selectedTheme });
    };
    const handleLogout = (e) => {
        logout();
        navigate("/login");
    };
    const handleSubmit = (e) => {
        dialogRefSettings.current.open = false;
    };
    const closeDialog = (e) => {
        dialogRefSettings.current.open = false;
    };
    return (
        <div className="settings-dialog">
            <md-dialog ref={dialogRefSettings}>
                <span slot="headline" className="headline">
                    Settings
                    <md-icon-button
                        className="dialog-close-button"
                        value="close"
                        aria-label="Close settings"
                        onClick={closeDialog}
                    >
                        <md-icon>close</md-icon>
                    </md-icon-button>
                </span>
                <form
                    className="settings-form"
                    slot="content"
                    method="dialog"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <md-filled-select
                            required
                            label="Target Language"
                            onInput={selectLanguage}
                            value={preferences.language}
                        >
                            <md-select-option
                                selected
                                value={preferences.language}
                                aria-label={preferences.language}
                            >
                                <div slot="headline">{preferences.language}</div>
                            </md-select-option>
                            {targetLanguageOptions.map((lang) => (
                                <md-select-option key={lang} value={lang}>
                                    <div slot="headline">{lang}</div>
                                </md-select-option>
                            ))}
                        </md-filled-select>
                    </div>
                    {isOtherSelected && (
                        <div>
                            <md-filled-text-field
                                label="Enter Other Language"
                                id="otherLanguageInput"
                                placeholder="Enter language"
                                value={preferences.language}
                                onBlur={handleOtherLanguageChange}
                                required
                            />
                        </div>
                    )}
                    <div>
                        <md-filled-text-field
                            label="Mastery Threshold"
                            supporting-text="Times a word must be seen to be mastered"
                            editable
                            type="number"
                            value={preferences.masteryThreshold}
                            required
                            onInput={handleMasteryThresholdChange}
                            min="1"
                        />
                    </div>
                    <div>
                        <label className="settings-toggle">
                            Pronunciation
                            <md-switch
                                selected={preferences.showTransliteration ? true : undefined}
                                onInput={handleTransliterationVisibilityChange}
                            ></md-switch>
                        </label>
                    </div>
                    <div>
                        <label className="settings-toggle">
                            <span>Dark Theme</span>
                            <md-switch
                                selected={preferences.theme === "dark" ? true : undefined}
                                onInput={handleThemeChange}
                            ></md-switch>
                        </label>
                    </div>{" "}
                    <md-outlined-button onClick={handleLogout}>Log Out</md-outlined-button>{" "}
                    <md-outlined-button onClick={() => navigate("/resetPassword")}>
                        Reset Password
                    </md-outlined-button>
                </form>
            </md-dialog>
        </div>
    );
};

export default SettingsDialog;
