// ScenarioGoals.js
import React, { useRef } from "react";
import AutoResizedTextField from "../../components/mdc/AutoResizedTextField.js";

const ScenarioGoals = ({ scenarioState }) => {
    const dialogRefScenarioGoals = useRef(null);

    if (Object.keys(scenarioState).length === 0 || scenarioState.languageLearnerRole === "")
        return;

    const openScenarioGoalsDialog = () => {
        if (dialogRefScenarioGoals.current) {
            dialogRefScenarioGoals.current.open = true;
        }
    };
    const closeDialog = () => {
        if (dialogRefScenarioGoals.current) {
            dialogRefScenarioGoals.current.open = false;
        }
    };
    return (
        <>
            <span className="scenario-goals-button">
                <md-icon-button onClick={openScenarioGoalsDialog}>
                    <md-icon>menu_open</md-icon>
                </md-icon-button>
            </span>
            <div>
                <md-dialog ref={dialogRefScenarioGoals}>
                    <span slot="headline" className="headline">
                        Scenario Goals
                        <md-icon-button
                            className="dialog-close-button"
                            value="close"
                            aria-label="Close Scenario Goals"
                            onClick={closeDialog}
                        >
                            <md-icon>close</md-icon>
                        </md-icon-button>
                    </span>
                    <form id="confirmationForm" slot="content" method="dialog">
                        <div className="scenario-manager-text-field">
                            <AutoResizedTextField
                                value={scenarioState.languageLearnerRole}
                                label="Language Learner Role"
                                placeholder="e.g., Student, Tourist"
                                readOnly
                            />
                        </div>
                        <div className="scenario-manager-text-field">
                            <AutoResizedTextField
                                value={scenarioState.conversationPartnerRole}
                                label="Conversation Partner Role"
                                placeholder="e.g., Teacher, Local Resident"
                                readOnly
                            />
                        </div>
                        <div className="scenario-manager-text-field">
                            <AutoResizedTextField
                                type="textarea"
                                value={scenarioState.informationToGive}
                                label="Information to Give"
                                placeholder="e.g., Personal details, Preferences"
                            />
                        </div>
                        <div className="scenario-manager-text-field">
                            <AutoResizedTextField
                                type="textarea"
                                value={scenarioState.informationToRequest}
                                label="Information to Request"
                                placeholder="e.g., Directions, Recommendations"
                                readOnly
                            />
                        </div>
                        <div className="scenario-manager-text-field">
                            <AutoResizedTextField
                                type="textarea"
                                value={scenarioState.context}
                                label="Context"
                                placeholder="e.g., Formal meeting, Casual conversation"
                                readOnly
                            />
                        </div>
                    </form>
                </md-dialog>
            </div>
        </>
    );
};

export default ScenarioGoals;
