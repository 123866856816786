import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext.js";

import "../../App.css";
import "./RegisterAndLoginPages.css";

function LoginPage() {
    return (
        <div className="app login-register">
            <LoginManager />
        </div>
    );
}

const LoginManager = () => {
    const navigate = useNavigate();
    const { auth, login, preferences } = useContext(GlobalContext);

    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const rememberMeInputRef = useRef(null);

    useEffect(() => {
        import(`../../themes/${preferences.theme}.css`)
            .then(() => {})
            .catch((err) => {
                console.error(`Failed to load ${preferences.theme} theme`, err);
            });
        document.body.className = preferences.theme;
    }, [preferences.theme]);

    async function loginUser(username, password, rememberMe) {
        setIsLoading(true);
        setErrorMessage("");
        try {
            // Call the global context login function
            const result = await login(username, password, rememberMe);
            if (result.success) {
                navigate("/conversation");
            } else if (result.status === 401) {
                setErrorMessage(result.message || "Invalid username or password.");
            } else if (result.status === 400) {
                setErrorMessage(
                    result.message || "Please provide both username and password."
                );
            } else if (result.status === 500) {
                setErrorMessage(
                    result.message || "An unexpected error occurred. Please try again later."
                );
            } else {
                setErrorMessage("An error occurred. Please try again.");
            }
        } catch (error) {
            console.error("Error during login:", error);
            setErrorMessage("Unable to connect to the server. Please try again.");
        } finally {
            setIsLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !usernameInputRef.current.checkValidity() ||
            !passwordInputRef.current.checkValidity()
        ) {
            setErrorMessage("Please ensure both fields are filled correctly.");
            return;
        }

        const usernameValue = usernameInputRef.current.value;
        const passwordValue = passwordInputRef.current.value;
        const rememberMe = rememberMeInputRef.current.checked;

        await loginUser(usernameValue, passwordValue, rememberMe);
    };

    return (
        <div className="login-register-container">
            <h1>Welcome Back!</h1>
            <form className="login-form" method="post" onSubmit={handleSubmit}>
                <div className="input-container">
                    <md-outlined-text-field
                        label="Enter Your Username"
                        id="loginUsernameInput"
                        name="username"
                        value={auth.username || ""}
                        ref={usernameInputRef}
                        placeholder="e.g., JohnDoe123"
                        required
                        editable
                        aria-label="Username"
                        autoFocus
                        autoComplete="username"
                        pattern="^[a-zA-Z0-9_]+$"
                        onInvalid={(e) => {
                            e.target.setCustomValidity(
                                "Username must contain only letters, numbers, and underscores."
                            );
                        }}
                        onInput={(e) => {
                            const value = e.target.value;
                            if (
                                value.length < 3 ||
                                value.length > 20 ||
                                !/^[a-zA-Z0-9_]+$/.test(value)
                            ) {
                                e.target.setCustomValidity(
                                    "Username must be 3-20 characters long and contain only letters, numbers, and underscores."
                                );
                            } else {
                                e.target.setCustomValidity("");
                            }
                        }}
                    />
                </div>

                <div className="input-container">
                    <md-outlined-text-field
                        label="Enter Your Password"
                        id="loginPasswordInput"
                        name="password"
                        type="password"
                        ref={passwordInputRef}
                        placeholder="Password"
                        required
                        editable
                        aria-label="Password"
                        autoComplete="current-password"
                    />
                </div>

                <div className="checkbox-container">
                    <md-checkbox
                        id="rememberMe"
                        aria-label="Remember Me"
                        ref={rememberMeInputRef}
                    ></md-checkbox>
                    <label htmlFor="rememberMe">Remember Me</label>
                </div>

                <div className="form-actions">
                    <md-filled-button type="submit">
                        {isLoading ? "Loading..." : "Continue"}
                    </md-filled-button>
                </div>
            </form>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <Link to="/forgot-password">Forgot password?</Link>
            <Link to="/register">Sign up</Link>
            <Link to="/">Homepage</Link>
        </div>
    );
};

export default LoginPage;
