import React, { useState, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import AutoResizedTextField from "../../components/mdc/AutoResizedTextField.js";

const MessageInput = forwardRef((props, ref) => {
    const [message, setMessage] = useState(localStorage.getItem("myInputValue") || "");
    const [isSending, setIsSending] = useState(false);

    // Expose a reset function to the parent via ref
    useImperativeHandle(ref, () => ({
        reset() {
            setMessage("");
            localStorage.removeItem("myInputValue");
        },
    }));

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setMessage(inputValue);
        localStorage.setItem("myInputValue", inputValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const trimmedMessage = message.trim();
        if (!trimmedMessage || isSending) return;

        setIsSending(true);
        setMessage("");
        localStorage.removeItem("myInputValue");

        // Add a temporary user message.
        props.setConversationHistory((prevHistory) => {
            // Remove any existing temporary user message.
            const newHistory = prevHistory.filter((msg) => msg.id !== "temp-user");
            if (props.preferences.language === "Not set")
                return [
                    ...newHistory,
                    {
                        id: "temp-user",
                        sender: "user",
                        targetLanguageMessage: { status: "done", value: trimmedMessage },
                    },
                    {
                        id: "temp-assistant",
                        sender: "assistant",
                        targetLanguageMessage: { status: "connecting" },
                    },
                ];
            return [
                ...newHistory,
                {
                    id: "temp-user",
                    sender: "user",
                    originalMessage: {
                        status: "loaded",
                        value: trimmedMessage,
                    },
                    targetLanguageMessage: { status: "connecting" },
                },
            ];
        });

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/send-message`,
                { message: trimmedMessage },
                { withCredentials: true }
            );
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <form className="message-form" onSubmit={handleSubmit}>
            <AutoResizedTextField
                variant="outlined"
                id="userInput"
                type="textarea"
                value={message}
                rows="1"
                placeholder="Type your mixed language message..."
                onInput={handleInputChange}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                        handleSubmit(e);
                        e.preventDefault();
                    }
                }}
            />
            <md-icon-button type="submit" className="send-button" softDisabled={isSending}>
                <md-icon filled>send</md-icon>
            </md-icon-button>
        </form>
    );
});

export default MessageInput;
