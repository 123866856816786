import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, requiredRole }) => {
  const { auth } = useContext(GlobalContext);
  
  if (!auth.isAuthenticated) return <Navigate to="/login" replace />;
  if (requiredRole && auth.userType !== requiredRole)
    return <Navigate to="/login" replace />;
  
  return children;
};

export default ProtectedRoute;
