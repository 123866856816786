// WordBankSidePanelContent.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./WordBankSidePanelContent.css";

const WordBankSidePanelContent = ({ selectedView, setSelectedView, wordDataTitles }) => {
    const navigate = useNavigate();

    const handleItemClick = (dataKey) => {
        setSelectedView({
            dataKey: dataKey,
            dataTitle: wordDataTitles[dataKey],
        });
        // Optionally close the panel if needed
    };

    return (
        <>
            <h3>Views</h3>
            <ul>
                {Object.keys(wordDataTitles).map((key) => (
                    <li
                        key={key}
                        className={
                            selectedView.dataKey === key ? "selected" : ""
                        }
                        onClick={() => handleItemClick(key)}
                    >
                        {wordDataTitles[key]}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default WordBankSidePanelContent;
