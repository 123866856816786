import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import "../../App.css";
import axios from "axios";
import WebSocketService from "../../context/WebSocketService.js";

import ConversationArea from "./ConversationArea.jsx";
import { GlobalContext } from "../../context/GlobalContext.js";

import SidePanel from "../../components/SidePanel/SidePanel.js";
import ConversationSidePanelContent from "./ConversationSidePanelContent.js";
import MessageInput from "./MessageInput.js";
import ScenarioGoals from "./ScenarioGoals.js";

axios.defaults.withCredentials = true;

function ConversationPage() {
    const [conversationDetails, setConversationDetails] = useState({
        conversationId: "",
        conversationHistory: [],
        persona: "",
    });
    const [scenarioState, setScenarioState] = useState({
        reasonForLearning: "",
        additionalNotes: "",
        languageLearnerRole: "",
        conversationPartnerRole: "",
        informationToGive: "",
        informationToRequest: "",
        context: "",
    });
    const [conversationHistory, setConversationHistory] = useState([]);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const { auth, preferences, setPreferences } = useContext(GlobalContext);

    const menuButtonRef = useRef(null);
    const messageInputRef = useRef(null);


    const togglePanel = useCallback(() => {
        setIsPanelOpen((prevIsOpen) => !prevIsOpen);
    }, []);

    useEffect(() => {
        if (preferences.theme) {
            import(`../../themes/${preferences.theme}.css`)
                .then(() => {})
                .catch((err) => {
                    console.error(`Failed to load ${preferences.theme} theme`, err);
                });
            document.body.className = preferences.theme;
        }
    }, [preferences.theme]);
    // Populate conversationHistory from parent prop
    useEffect(() => {
        if (conversationDetails && Array.isArray(conversationDetails.conversationHistory)) {
            const newHistory = conversationDetails.conversationHistory.map((message) => {
                const {
                    id,
                    targetLanguageMessage,
                    originalMessage,
                    morphemeTranslationDetails,
                    fullSentenceTranslation,
                } = message;

                // Helper function to structure each field
                const structureField = (value) => ({
                    status: "loaded",
                    statusMessage: null,
                    value,
                });

                // Construct the new message object
                const newMessage = {
                    id,
                    sender: message.sender,
                    _id: message._id,
                    ...(message.morphemeArray && {
                        morphemeArray: message.morphemeArray,
                    }),
                    ...(targetLanguageMessage && {
                        targetLanguageMessage: structureField(targetLanguageMessage),
                    }),
                    ...(originalMessage && {
                        originalMessage: structureField(originalMessage),
                    }),
                    ...(morphemeTranslationDetails && {
                        morphemeTranslationDetails: structureField(morphemeTranslationDetails),
                    }),
                    ...(fullSentenceTranslation && {
                        fullSentenceTranslation: structureField(fullSentenceTranslation),
                    }),
                };
                return newMessage;
            });

            setConversationHistory(newHistory);
        }
    }, [conversationDetails]);

    useEffect(() => {
        const fetchConversationHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/request-conversation-history`
                );
                const { conversationDetails, preferences } = response.data;
                setConversationDetails(conversationDetails);
                setScenarioState(conversationDetails.scenario);
                setPreferences(preferences);
            } catch (error) {
                console.error("Error fetching conversation history:", error);
            }
        };
        fetchConversationHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleMessage = (data) => {
            if (data.conversationDetails) {
                const { scenario, ...processedConversationDetails } = data.conversationDetails;
                setConversationDetails(processedConversationDetails);
                setScenarioState(scenario);
            }
            if (data.preferences) {
                setPreferences(data.preferences);
            }
        };

        WebSocketService.on("message", handleMessage);

        return () => {
            WebSocketService.off("message", handleMessage);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResetConversation = async () => {
        // Reset the message input using the exposed ref function
        if (messageInputRef.current) {
            messageInputRef.current.reset();
        }
        setConversationDetails({
            conversationId: null,
            conversationHistory: [],
        });
        setScenarioState({
            reasonForLearning: "",
            additionalNotes: "",
            languageLearnerRole: "",
            conversationPartnerRole: "",
            informationToGive: "",
            informationToRequest: "",
            context: "",
        });
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/reset-conversation`);
        } catch (error) {
            console.error("Error resetting conversation:", error);
        }
    };

    return (
        <div className={preferences.theme}>
            <div className="app">
                <div className="app-container">
                    <header className="app-header">
                        <span className="navigation-menu-button">
                            <md-icon-button ref={menuButtonRef} onClick={togglePanel}>
                                <md-icon>menu</md-icon>
                            </md-icon-button>
                        </span>
                    </header>
                    {scenarioState.languageLearnerRole !== "" && (
                        <ScenarioGoals scenarioState={scenarioState} />
                    )}
                    <SidePanel
                        isOpen={isPanelOpen}
                        togglePanel={togglePanel}
                        navigationItems={[
                            { label: "Home", path: "/" },
                            { label: "Word Bank", path: "/wordBank" },
                            { label: "Progress Dashboard", path: "/progressDashboard" },
                            ...(auth.userType === "admin"
                                ? [
                                      { label: "Login Page", path: "/login" },
                                      { label: "Admin Page", path: "/admin" },
                                  ]
                                : []),
                        ]}
                        menuButtonRef={menuButtonRef}
                        className="app-side-panel"
                        preferences={preferences}
                        setPreferences={setPreferences}
                    >
                        <ConversationSidePanelContent
                            conversationDetails={conversationDetails}
                            setConversationDetails={setConversationDetails}
                            scenarioState={scenarioState}
                            setScenarioState={setScenarioState}
                            handleResetConversation={handleResetConversation}
                        />
                    </SidePanel>
                    <ConversationArea
                        preferences={preferences}
                        setPreferences={setPreferences}
                        conversationHistory={conversationHistory}
                        setConversationHistory={setConversationHistory}
                    />
                    <MessageInput
                        ref={messageInputRef}
                        conversationHistory={conversationHistory}
                        setConversationHistory={setConversationHistory}
                        preferences={preferences}
                    />
                </div>
            </div>
        </div>
    );
}

export default ConversationPage;
