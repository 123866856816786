// MessageContainer.jsx

import React, { useState, useEffect, useRef, memo } from "react";
import axios from "axios";

function MessageContainer({
    message,
    preferences,
    setActiveMessageId,
    isExpanded,
    expandedMessageId,
    setExpandedMessageId,
    selectedMorphemes,
    setSelectedMorphemes,
    fetchMorphemes,
}) {
    const [showMorphemeTranslationDetails, setShowMorphemeTranslationDetails] = useState(true);
    const toggleMorphemeTranslationDetails = () => {
        // Disable toggling if isExpanded is true
        if (isExpanded) return;
        setShowMorphemeTranslationDetails((prev) => !prev);
    };

    // Refs for managing press state
    const pressTimerRef = useRef(null);
    const isLongPressRef = useRef(false);
    const isClickEventRef = useRef(false);
    const isScrollingRef = useRef(false);
    const initialPointerPosRef = useRef({ x: 0, y: 0 });

    // Movement threshold in pixels (vertical only)
    const MOVE_THRESHOLD_VERTICAL_MOUSE = 10;
    const MOVE_THRESHOLD_VERTICAL_TOUCH = 20;

    // Ref to store current movement threshold
    const moveThresholdRef = useRef(MOVE_THRESHOLD_VERTICAL_MOUSE);

    // Cleanup timer on unmount
    useEffect(() => {
        return () => {
            clearTimeout(pressTimerRef.current);
        };
    }, []);

    // Start the long press timer and record initial position
    const handlePressStart = (event) => {
        // Only respond to primary button (usually left mouse button) or touch/pen
        if (event.pointerType === "mouse" && event.button !== 0) return;

        event.stopPropagation();

        // Reset scrolling flag on new press
        isScrollingRef.current = false;
        isClickEventRef.current = true;
        isLongPressRef.current = false;

        // Record initial pointer position
        initialPointerPosRef.current = { x: event.clientX, y: event.clientY };

        // Determine vertical movement threshold based on pointer type
        const moveThreshold =
            event.pointerType === "touch"
                ? MOVE_THRESHOLD_VERTICAL_TOUCH
                : MOVE_THRESHOLD_VERTICAL_MOUSE;

        // Store the threshold in a ref for access in handlePointerMove
        moveThresholdRef.current = moveThreshold;

        pressTimerRef.current = setTimeout(() => {
            if (!isScrollingRef.current) {
                isLongPressRef.current = true;
                setActiveMessageId(message.id); // Set active message on long press
            }
        }, 500); // Long press duration
    };

    // Handle pointer movement with vertical threshold
    const handlePointerMove = (event) => {
        const { y } = initialPointerPosRef.current;
        const deltaY = Math.abs(event.clientY - y);
        const currentMoveThreshold = moveThresholdRef.current;

        if (deltaY > currentMoveThreshold) {
            isScrollingRef.current = true;
            clearTimeout(pressTimerRef.current); // Cancel long press if moving beyond threshold
        }
    };

    // Handle pointer cancel (e.g., touch cancel)
    const handlePointerCancel = () => {
        // Reset all flags and clear timer
        isClickEventRef.current = false;
        isLongPressRef.current = false;
        isScrollingRef.current = false;
        clearTimeout(pressTimerRef.current);
    };

    // Handle press end
    const handlePressEnd = (event) => {
        // Only respond to primary button or touch/pen
        if (event.pointerType === "mouse" && event.button !== 0) return;

        event.stopPropagation();
        clearTimeout(pressTimerRef.current);

        if (!isLongPressRef.current && isClickEventRef.current && !isScrollingRef.current) {
            toggleMorphemeTranslationDetails();
        }

        // Reset refs
        isClickEventRef.current = false;
        isLongPressRef.current = false;
        isScrollingRef.current = false;
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    return (
        <div
            className={`message ${message.sender} ${isExpanded ? "message-expanded" : ""}`} // Apply the expanded class conditionally
            onPointerDown={handlePressStart}
            onPointerUp={handlePressEnd}
            onPointerMove={handlePointerMove}
            onPointerCancel={handlePointerCancel}
            style={{ userSelect: "none" }}
            onContextMenu={handleContextMenu}
        >
            <MessageComponent
                message={message}
                preferences={preferences}
                showMorphemeTranslationDetails={showMorphemeTranslationDetails}
                isExpanded={isExpanded}
                setSelectedMorphemes={setSelectedMorphemes}
            />
            {isExpanded && (
                <MorphemeMenu
                    selectedMorphemes={selectedMorphemes}
                    expandedMessageId={expandedMessageId}
                    setExpandedMessageId={setExpandedMessageId}
                    fetchMorphemes={fetchMorphemes}
                />
            )}
        </div>
    );
}

function MessageField({ fieldData }) {
    const { status, statusMessage, value } = fieldData || {};
    // Determine the text to display
    const displayText = statusMessage || value || "";

    // Determine the CSS class based on status
    const statusClass = status ? status.toLowerCase() : "";

    // Render different JSX based on status using switch
    const renderContent = () => {
        switch (status) {
            case "connecting":
            case "pending":
            case "generating":
                return <div className={`${statusClass}`}>{status}...</div>;
            case "streaming":
            case "done":
            case "loaded":
                return <div className={`${statusClass}`}>{displayText}</div>;
            case "error":
                return <div className={`${statusClass}`}>{`Error: ${statusMessage}`}</div>;
            default:
                return <div className={`${statusClass}`}>{displayText}</div>;
        }
    };

    return renderContent();
}

// OriginalMessage Component
function OriginalMessage({ originalMessage }) {
    return (
        originalMessage?.value && (
            <div className="original-message message-field">
                <MessageField fieldData={originalMessage} />
            </div>
        )
    );
}

// TargetLanguageMessage Component
function TargetLanguageMessage({ targetLanguageMessage }) {
    return (
        <div className="message-field">
            <MessageField fieldData={targetLanguageMessage} />
        </div>
    );
}

// FullSentenceTranslation Component
function FullSentenceTranslation({ fullSentenceTranslation }) {
    return (
        fullSentenceTranslation?.value && (
            <div className="full-sentence-translation message-field">
                <MessageField fieldData={fullSentenceTranslation} />
            </div>
        )
    );
}

// MorphemeField Component using switch statement
function TranslationPair({ morpheme, preferences, isExpanded, onClick }) {
    const {
        originalMorpheme,
        translatedMorpheme,
        transliteratedMorpheme,
        opacity,
        isSelected,
    } = morpheme;
    return (
        <div
            className={`translation-pair ${isExpanded ? "message-expanded" : ""} ${
                isSelected ? "selected-morpheme" : ""
            }`}
            onClick={onClick}
        >
            <span className="original-word">{originalMorpheme}</span>
            {preferences.showTransliteration && (
                <span className="transliteration-word">{transliteratedMorpheme}</span>
            )}
            <span className="translated-word" style={{ opacity }}>
                {translatedMorpheme}
            </span>
        </div>
    );
}

// MorphemesComponent
function MorphemesComponent({ message, preferences, isExpanded, setSelectedMorphemes }) {
    const { morphemeTranslationDetails, morphemeArray } = message;
    const { status, statusMessage } = morphemeTranslationDetails;
    const rtlLanguages = new Set([
        "Arabic",
        "Hebrew",
        "Persian",
        "Farsi",
        "Urdu",
        "Yiddish",
        "Kurdish",
        "Pashto",
    ]);

    const isRtlLanguage = (language) => rtlLanguages.has(language);

    const isRtl = isRtlLanguage(preferences.language);

    const handleMorphemeClick = (word) => {
        if (!isExpanded) return;
        setSelectedMorphemes((prevSelected) =>
            prevSelected.includes(word)
                ? prevSelected.filter((morpheme) => morpheme !== word)
                : [...prevSelected, word]
        );
    };
    const renderMorphemeContent = () => {
        switch (status) {
            case "connecting":
            case "pending":
            case "generating":
                const placeholderMorpheme = {
                    originalMorpheme: "",
                    translatedMorpheme: "",
                    transliteratedMorpheme: "",
                    opacity: 0,
                    isSelected: false,
                };
                return (
                    <TranslationPair
                        morpheme={placeholderMorpheme}
                        preferences={preferences}
                        isExpanded={false}
                        onClick={() =>
                            handleMorphemeClick(placeholderMorpheme.originalMorpheme)
                        }
                    />
                );
            case "streaming":
            case "done":
            case "loaded":
                return morphemeArray.map((morpheme) => (
                    <TranslationPair
                        key={morpheme.key}
                        morpheme={morpheme}
                        preferences={preferences}
                        isExpanded={isExpanded}
                        onClick={() => handleMorphemeClick(morpheme.originalMorpheme)}
                    />
                ));
            case "error":
                return <div>{`Error: ${statusMessage}`}</div>;
            default:
                return <div>Something went wrong...</div>;
        }
    };

    return (
        <div
            className={`translation-grid ${isExpanded ? "message-expanded" : ""} ${
                isRtl ? "rtl" : "ltr"
            }`}
        >
            {renderMorphemeContent()}
        </div>
    );
}

// MessageComponent
function MessageComponent({
    message,
    preferences,
    showMorphemeTranslationDetails,
    isExpanded,
    setSelectedMorphemes,
}) {
    useEffect(() => {
        if (!isExpanded) {
            setSelectedMorphemes([]);
        }
    }, [isExpanded, setSelectedMorphemes]);

    // Destructure message fields for readability
    const {
        originalMessage,
        targetLanguageMessage,
        fullSentenceTranslation,
        morphemeTranslationDetails,
    } = message;
    const shouldShowMorphemes =
        showMorphemeTranslationDetails &&
        (morphemeTranslationDetails?.status || morphemeTranslationDetails?.value);
    return (
        <span>
            <OriginalMessage originalMessage={originalMessage} />

            {shouldShowMorphemes ? (
                <div className="message-field">
                    <MorphemesComponent
                        message={message}
                        preferences={preferences}
                        isExpanded={isExpanded}
                        setSelectedMorphemes={setSelectedMorphemes}
                    />
                </div>
            ) : (
                <TargetLanguageMessage targetLanguageMessage={targetLanguageMessage} />
            )}
            <FullSentenceTranslation fullSentenceTranslation={fullSentenceTranslation} />
        </span>
    );
}

function MorphemeMenu({
    selectedMorphemes,
    expandedMessageId,
    setExpandedMessageId,
    fetchMorphemes,
}) {
    const handleMarkAsMastered = async () => {
        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL}/update-morphemes-as-mastered`,
                { originalMorphemes: selectedMorphemes, expandedMessageId },
                {
                    withCredentials: true,
                }
            );
            await fetchMorphemes(); // Ensure fetchMorphemes completes before proceeding
        } catch (error) {
            console.error("Error marking morphemes as mastered:", error);
        }
    };

    const handleRefreshMastery = async () => {
        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL}/update-morphemes-as-not-mastered`,
                { originalMorphemes: selectedMorphemes, expandedMessageId },
                {
                    withCredentials: true,
                }
            );
            fetchMorphemes();
        } catch (error) {
            console.error("Error refreshing morpheme mastery:", error);
        }
    };

    const handleCancel = () => {
        setExpandedMessageId(null);
    };
    return (
        <div className="morpheme-menu">
            <md-outlined-button onClick={handleMarkAsMastered}>
                Mark as Mastered
            </md-outlined-button>
            <md-outlined-button onClick={handleRefreshMastery}>
                Refresh Mastery
            </md-outlined-button>
            <md-outlined-button onClick={handleCancel}>Close</md-outlined-button>
        </div>
    );
}

// Custom comparison function for React.memo
const areEqual = (prevProps, nextProps) => {
    const prevMessage = prevProps.message;
    const nextMessage = nextProps.message;

    // Compare primitive fields and field properties
    if (
        prevMessage.id !== nextMessage.id ||
        // Compare originalMessage properties
        prevMessage.originalMessage?.value !== nextMessage.originalMessage?.value ||
        prevMessage.originalMessage?.status !== nextMessage.originalMessage?.status ||
        prevMessage.originalMessage?.statusMessage !==
            nextMessage.originalMessage?.statusMessage ||
        // Compare targetLanguageMessage properties
        prevMessage.targetLanguageMessage?.value !==
            nextMessage.targetLanguageMessage?.value ||
        prevMessage.targetLanguageMessage?.status !==
            nextMessage.targetLanguageMessage?.status ||
        prevMessage.targetLanguageMessage?.statusMessage !==
            nextMessage.targetLanguageMessage?.statusMessage ||
        // Compare fullSentenceTranslation properties
        prevMessage.fullSentenceTranslation?.value !==
            nextMessage.fullSentenceTranslation?.value ||
        prevMessage.fullSentenceTranslation?.status !==
            nextMessage.fullSentenceTranslation?.status ||
        prevMessage.fullSentenceTranslation?.statusMessage !==
            nextMessage.fullSentenceTranslation?.statusMessage ||
        // Compare other props
        prevProps.isExpanded !== nextProps.isExpanded ||
        prevProps.preferences.showTransliteration !==
            nextProps.preferences.showTransliteration ||
        prevProps.preferences.language !== nextProps.preferences.language
    ) {
        return false;
    }

    // Compare morphemeArray length
    if (
        prevMessage.morphemeArray &&
        nextMessage.morphemeArray &&
        prevMessage.morphemeArray.length !== nextMessage.morphemeArray.length
    ) {
        return false;
    }

    // Compare each morpheme object
    if (prevMessage.morphemeArray && nextMessage.morphemeArray) {
        for (let i = 0; i < prevMessage.morphemeArray.length; i++) {
            const prevMorpheme = prevMessage.morphemeArray[i];
            const nextMorpheme = nextMessage.morphemeArray[i];
            if (
                prevMorpheme.originalMorpheme !== nextMorpheme.originalMorpheme ||
                prevMorpheme.translatedMorpheme !== nextMorpheme.translatedMorpheme ||
                prevMorpheme.transliteratedMorpheme !== nextMorpheme.transliteratedMorpheme ||
                prevMorpheme.opacity !== nextMorpheme.opacity ||
                prevMorpheme.isSelected !== nextMorpheme.isSelected
            ) {
                return false;
            }
        }
    }

    // If all relevant props are equal, skip re-render
    return true;
};

// Export the memoized component
export default memo(MessageContainer, areEqual);
