import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import "../../App.css";
import "../../themes/dark.css"; // Ensure this file defines the CSS variable
import SidePanel from "../../components/SidePanel/SidePanel";
import styles from "./ProgressDashboard.module.css";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ProgressDashboard = () => {
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const menuButtonRef = useRef(null);

  const togglePanel = useCallback(() => {
    setIsPanelOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  // Apply dark theme by adding 'dark' class to body
  useEffect(() => {
    document.body.classList.add("dark");
    return () => {
      document.body.classList.remove("dark");
    };
  }, []);

  // Fetch graph data from API
  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get-graph-data`,
        );
        const data = response.data;
        setGraphData(data.progressGraphData);
      } catch (err) {
        console.error("Error fetching graph data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGraphData();
  }, []);

  // Function to get the computed color from CSS variable
  const getLabelColor = () => {
    const color = getComputedStyle(document.body)
      .getPropertyValue("--md-sys-color-on-surface")
      .trim();
    return color || "white"; // Fallback color if variable is not defined
  };

  // Prepare chart data using useMemo to avoid unnecessary recalculations
  const { totalEncounteredChartData, uniqueMasteredChartData: topMasteredChartData } = useMemo(() => {
    if (!graphData || graphData.length === 0) {
      return {
        totalEncounteredChartData: { labels: [], datasets: [] },
        uniqueMasteredChartData: { labels: [], datasets: [] },
      };
    }

    const labels = graphData.map((d) => d.label);
    const totalEncounteredWords = graphData.map((d) => d.totalEncounteredWords);
    const masteredWords = graphData.map((d) => d.masteredWordTotal);
    const topWords = graphData.map((d) => d.topWordsCount);

    // Dataset for Total Encountered Words
    const totalEncounteredChartData = {
      labels,
      datasets: [
        {
          label: "Total Encountered Words",
          data: totalEncounteredWords,
          backgroundColor: "rgba(255, 159, 64, 0.7)", // Orange
          borderColor: "rgba(255, 159, 64, 1)",
          borderWidth: 1,
        },
      ],
    };

    // Dataset for Mastered and Top 80% Words
    const uniqueMasteredChartData = {
      labels,
      datasets: [
        {
          label: "Mastered Words",
          data: masteredWords,
          backgroundColor: "rgba(153, 102, 255, 0.7)", // Purple
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
        },
        {
          label: "Top 80% Words",
          data: topWords,
          backgroundColor: "rgba(75, 192, 192, 0.7)", // Teal
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };

    return { totalEncounteredChartData, uniqueMasteredChartData };
  }, [graphData]);

  // Define Chart.js options with dynamic label colors
  const totalEncounteredOptions = useMemo(() => {
    const labelColor = getLabelColor();

    return {
      plugins: {
        title: {
          display: true,
          text: "Total Encountered Words",
          font: { size: 18 },
          color: labelColor,
        },
        tooltip: { mode: "index", intersect: false },
        legend: {
          position: "top",
          labels: { color: labelColor },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      interaction: { mode: "index", intersect: false },
      scales: {
        x: {
          stacked: false,
          title: {
            display: true,
            text: "Messages",
            font: { size: 14 },
            color: labelColor,
          },
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          ticks: { display: false },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Total Encountered Words",
            font: { size: 14 },
            color: labelColor,
          },
          ticks: { color: labelColor },
        },
      },
    };
  }, []);

  const topMasteredOptions = useMemo(() => {
    const labelColor = getLabelColor();

    return {
      plugins: {
        title: {
          display: true,
          text: "Total Top 80% and Mastered Words",
          font: { size: 18 },
          color: labelColor,
        },
        tooltip: { mode: "index", intersect: false },
        legend: {
          position: "top",
          labels: { color: labelColor },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      interaction: { mode: "index", intersect: false },
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
            text: "Messages",
            font: { size: 14 },
            color: labelColor,
          },
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          ticks: { display: false },
        },
        y: {
          stacked: true,
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of Words",
            font: { size: 14 },
            color: labelColor,
          },
          ticks: { color: labelColor },
        },
      },
    };
  }, []);

  if (loading) {
    return <div>Loading graph...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="app">
      <div className="app-container">
        <header className="app-header">
          <span style={{ marginRight: "auto" }}>
            <md-icon-button ref={menuButtonRef} onClick={togglePanel}>
              <md-icon>menu</md-icon>
            </md-icon-button>
          </span>
        </header>
        <SidePanel
          isOpen={isPanelOpen}
          togglePanel={togglePanel}
          navigationItems={[
            { label: "Home", path: "/" },
            { label: "Conversation", path: "/conversation" },
            { label: "Word Bank", path: "/wordbank" },
          ]}
          menuButtonRef={menuButtonRef}
          className=""
        />
        <div className={styles.dashboardContainer}>
          {/* First Chart: Total Encountered Words */}
          <div className={styles.chartWrapper}>
            {totalEncounteredChartData.datasets.length > 0 ? (
              <Bar
                options={totalEncounteredOptions}
                data={totalEncounteredChartData}
                height={400}
              />
            ) : (
              <div>No data available to display the chart.</div>
            )}
          </div>
          {/* Second Chart: Top 80% and Mastered Words */}
          <div className={styles.chartWrapper}>
            {topMasteredChartData.datasets.length > 0 ? (
              <Bar
                options={topMasteredOptions}
                data={topMasteredChartData}
                height={400}
              />
            ) : (
              <div>No data available to display the chart.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressDashboard;
