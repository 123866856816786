import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import "./RegisterAndLoginPages.css";

function RegisterPage() {
    return (
        <div className="app login-register">
            <RegisterManager />
        </div>
    );
}

const RegisterManager = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState();
    const [feedbackMessage, setFeedbackMessage] = useState("");

    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const retypePasswordInputRef = useRef(null);
    const accessCodeInputRef = useRef(null);

    useEffect(() => {
        import(`../../themes/dark.css`)
            .then(() => {})
            .catch((err) => {
                console.error(`Failed to load dark theme`, err);
            });
        document.body.className = "dark";
    }, []);

    async function registerUser(username, password, accessCode) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ username, password, accessCode }),
            });

            const result = await response.json();
            console.log("register result: ", result);
            switch (response.status) {
                case 201:
                    setFeedbackMessage("");
                    navigate("/login");
                    break;
                case 400:
                    setFeedbackMessage(
                        result.message ||
                            "Invalid request. Please ensure all fields are correctly filled."
                    );
                    break;
                case 409:
                    setFeedbackMessage(
                        result.message ||
                            "An account with this username already exists. Please choose a different username."
                    );
                    break;
                default:
                    setFeedbackMessage(
                        result.message ||
                            "An error occurred during registration. Please try again."
                    );
                    break;
            }
        } catch (error) {
            console.error("Network or server error:", error);
            alert(
                "Unable to connect to the server. Please check your internet connection and try again."
            );
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate using built-in browser validation from material text field
        if (
            !usernameInputRef.current.checkValidity() ||
            !passwordInputRef.current.checkValidity() ||
            !retypePasswordInputRef.current.checkValidity() ||
            !accessCodeInputRef.current.checkValidity()
        ) {
            setFeedbackMessage("Please ensure all fields are filled correctly.");
            return;
        }

        const username = usernameInputRef.current.value;
        const password = passwordInputRef.current.value;
        const retypePassword = retypePasswordInputRef.current.value;
        const accessCode = accessCodeInputRef.current.value;

        if (password.length < 8) {
            setFeedbackMessage("Password must be at least 8 characters long.");
            return;
        }

        if (password !== retypePassword) {
            setFeedbackMessage("Passwords do not match.");
            return;
        }
        registerUser(username, password, accessCode);
        setUsername(username);
    };

    const handlePasswordChange = (event) => {
        passwordInputRef.current.value = event.target.value;
    };

    return (
        <div className="login-register-container">
            <h1>Create Your Account</h1>
            <form
                id="registerForm"
                method="dialog"
                onSubmit={handleSubmit}
            >
                <div className="input-container">
                    <md-outlined-text-field
                        label="Enter Your Username"
                        id="registerUsernameInput"
                        value={username}
                        ref={usernameInputRef}
                        placeholder="e.g., JohnDoe123"
                        required
                        editable
                        aria-label="Username"
                        pattern="^[a-zA-Z0-9_]+$"
                        supporting-text="Username must contain only letters, numbers, and underscores."
                        minlength="3"
                        maxlength="20"
                        onInvalid={(e) => {
                            // Set the custom error message when the field is initially found invalid
                            e.target.setCustomValidity(
                                "Username must be 3-20 characters long and contain only letters, numbers, and underscores."
                            );
                        }}
                        onInput={(e) => {
                            const value = e.target.value;
                            // Check if the current value meets all criteria.
                            if (
                                value.length < 3 ||
                                value.length > 20 ||
                                !/^[a-zA-Z0-9_]+$/.test(value)
                            ) {
                                // If not valid, maintain the custom error message
                                e.target.setCustomValidity(
                                    "Username must be 3-20 characters long and contain only letters, numbers, and underscores."
                                );
                            } else {
                                // Only clear the error if the value is valid
                                e.target.setCustomValidity("");
                            }
                        }}
                    />
                </div>
                <div className="input-container">
                    <md-outlined-text-field
                        label="Create a Password"
                        id="registerPasswordInput"
                        type="password"
                        onInput={handlePasswordChange}
                        ref={passwordInputRef}
                        placeholder="Password"
                        required
                        editable
                        aria-label="Create Password"
                        minlength="8"
                        maxlength="256"
                    />
                </div>
                <div className="input-container">
                    <md-outlined-text-field
                        label="Retype Password"
                        id="retypeRegisterPasswordInput"
                        type="password"
                        ref={retypePasswordInputRef}
                        placeholder="Retype Password"
                        required
                        editable
                        aria-label="Retype Password"
                        minlength="8"
                    />
                </div>
                <div className="input-container">
                    <md-outlined-text-field
                        label="Enter Your Access Code"
                        ref={accessCodeInputRef}
                        placeholder="A1A1A1"
                        required
                        editable
                        aria-label="Access Code"
                    />
                </div>
                <ul
                    style={{
                        textAlign: "left",
                        lineHeight: "1.5",
                        listStylePosition: "outside",
                    }}
                >
                    <li>Beta testers, please use an identifiable username.</li>
                    <li style={{ marginBottom: "10px" }}>
                        Do not reuse passwords
                        <a
                            href="https://xkcd.com/936/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: "block", lineHeight: "0" }}
                        >
                            (Try a passphrase!)
                        </a>
                    </li>
                    <li>Do not add sensitive information</li>
                </ul>
                <div className="form-actions">
                    <md-filled-button type="submit">Continue</md-filled-button>
                </div>
            </form>
            <div className="error-message">{feedbackMessage}</div>
            <span>
                Already have an account? <Link to="/login">Sign in</Link>
            </span>
            <Link to="/">Homepage</Link>
        </div>
    );
};

export default RegisterPage;
