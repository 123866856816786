// ConversationSidePanelContent.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import ScenarioManager from "../../components/ScenarioManager";
import "./ConversationSidePanelContent.css";
import WebSocketService from "../../context/WebSocketService";

const ConversationSidePanelContent = ({
    conversationDetails,
    setConversationDetails,
    scenarioState,
    setScenarioState,
    handleResetConversation,
}) => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const scenarioManagerRef = useRef(null);

    const openScenarioManager = () => {
        scenarioManagerRef.current.openScenarioManagerDefault();
    };

    const createAssistantConversation = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/create-petpal-conversation`, {
            withCredentials: true,
        });
        fetchConversations();
        // Optionally close the panel if needed
    };

    const fetchConversationById = useCallback(
        async (conversation) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/get-conversation/${conversation._id}`
                );
                const { _id, sentences, scenario, persona } = response.data;
                const conversationId = _id;
                const conversationHistory = sentences;
                setConversationDetails({ conversationId, conversationHistory, persona });

                setScenarioState(scenario);
                setSelectedConversation(conversation);
                // Optionally close the panel if needed
            } catch (error) {
                console.error("Error fetching conversation:", error);
            }
        },
        [setConversationDetails, setScenarioState]
    );

    const handleDeleteConversation = async (conversationId) => {
        try {
            if (conversationId === conversationDetails.conversationId) {
                handleResetConversation();
            }
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/delete-conversation/${conversationId}`
            );
            fetchConversations();
        } catch (error) {
            console.error("Error deleting conversation:", error);
        }
    };
    useEffect(() => {
        const handleMessage = (data) => {
            if (data.conversations) {
                setConversations(data.conversations);
            }
        };
        WebSocketService.on("message", handleMessage);
        return () => {
            WebSocketService.off("message", handleMessage);
        };
    }, []);

    const fetchConversations = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-conversation-titles`
            );
            setConversations(response.data);
        } catch (error) {
            console.error("Error fetching conversation titles:", error);
        }
    };

    useEffect(() => {
        fetchConversations();
    }, []);

    useEffect(() => {
        const setupMenuToggle = (menuSelector, anchorSelector) => {
            const toggleMenu = () => {
                const menuEl = document.body.querySelector(menuSelector);
                if (menuEl) {
                    menuEl.open = !menuEl.open;
                }
            };

            const anchorEl = document.body.querySelector(anchorSelector);
            if (anchorEl) {
                anchorEl.addEventListener("click", toggleMenu);
            }

            return () => {
                if (anchorEl) {
                    anchorEl.removeEventListener("click", toggleMenu);
                }
            };
        };

        const cleanupFunctions = conversations.map((conversation) => {
            const buttonId = `conversation-actions-menu-anchor-${conversation._id}`;
            const menuId = `conversation-actions-menu-${conversation._id}`;
            return setupMenuToggle(`#${menuId}`, `#${buttonId}`);
        });

        return () => {
            cleanupFunctions.forEach((cleanup) => cleanup());
        };
    }, [conversations]);

    return (
        <div className="side-panel-content">
            <h3 className="h3-select-conversation">
                <span>Select Conversation</span>
                <md-icon-button onClick={handleResetConversation}>
                    <md-icon>edit_square</md-icon>
                </md-icon-button>
            </h3>
            <ul>
                <li onClick={openScenarioManager}>
                    New Conversation
                    <md-icon>add</md-icon>
                </li>
                <li onClick={createAssistantConversation}>
                    Restart Tutorial
                    <md-icon>Help</md-icon>
                </li>
                {conversations.map((conversation) => {
                    const buttonId = `conversation-actions-menu-anchor-${conversation._id}`;
                    const menuId = `conversation-actions-menu-${conversation._id}`;
                    return (
                        <li
                            key={conversation._id}
                            className={
                                conversation._id === selectedConversation?._id
                                    ? "selected"
                                    : ""
                            }
                            onClick={async () => {
                                await fetchConversationById(conversation);
                            }}
                        >
                            {conversation.title}
                            <span className="inline-flex-container">
                                <md-icon-button
                                    id={buttonId}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <md-icon>more_horiz</md-icon>
                                </md-icon-button>
                                <md-menu
                                    anchor-corner="start-end"
                                    menu-corner="start-end"
                                    id={menuId}
                                    anchor={buttonId}
                                >
                                    <md-menu-item
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteConversation(conversation._id);
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "var(--md-sys-color-error)",
                                            }}
                                        >
                                            Delete
                                        </span>
                                        <md-icon
                                            style={{
                                                color: "var(--md-sys-color-error)",
                                            }}
                                            slot="end"
                                        >
                                            delete
                                        </md-icon>
                                    </md-menu-item>
                                </md-menu>
                            </span>
                        </li>
                    );
                })}
            </ul>

            <ScenarioManager
                ref={scenarioManagerRef}
                scenarioState={scenarioState}
                setScenarioState={setScenarioState}
            />
        </div>
    );
};

export default ConversationSidePanelContent;
