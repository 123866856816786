import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import WebSocketService from "./WebSocketService";
export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        accessToken: null,
        username: null,
        userType: null,
        affiliatedOrganization: null,
    });
    const [preferences, setPreferences] = useState({
        language: null,
        showTransliteration: null,
        theme: "dark",
        masteryThreshold: null,
    });
    const [loading, setLoading] = useState(true);

    // On mount, attempt to refresh the token
    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/refresh-token`,
                {},
                { withCredentials: true }
            )
            .then((response) => {
                if (response.data.valid) {
                    const { accessToken, username, userType, affiliatedOrganization } =
                        response.data;
                    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
                    setAuth({
                        isAuthenticated: true,
                        accessToken,
                        username,
                        userType,
                        affiliatedOrganization,
                    });
                }
            })
            .catch(() => {
                setAuth({
                    isAuthenticated: false,
                    accessToken: null,
                    username: null,
                    userType: null,
                    affiliatedOrganization: null,
                });
            })
            .finally(() => setLoading(false));
    }, []);

    const login = async (username, password, rememberMe) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({ username, password, rememberMe }),
            });
            const result = await response.json();
            if (response.ok && result.loginSuccess) {
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${result.accessToken}`;
                setAuth({
                    isAuthenticated: true,
                    accessToken: result.accessToken,
                    username: result.username,
                    userType: result.userType,
                    affiliatedOrganization: result.affiliatedOrganization,
                });
                WebSocketService.connectWebSocket();
                return { success: true };
            }
            console.log("result", result);
            return { success: false, message: result.message, status: response.status };
        } catch (error) {
            console.log("error", error);
            return { success: false, message: "Login failed. Please try again." };
        }
    };

    const logout = async () => {
        // Request the server to remove the refreshToken cookie
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/logout`,
                {},
                { withCredentials: true }
            );
        } catch (error) {
            console.error("Error during logout while removing refreshToken cookie:", error);
        }
        localStorage.removeItem("myInputValue");

        // Clear client-side authentication state and axios header
        setAuth({
            isAuthenticated: false,
            accessToken: null,
            username: null,
            userType: null,
            affiliatedOrganization: null,
        });
        delete axios.defaults.headers.common["Authorization"];
    };

    // You can add more global functions here if needed

    const value = {
        auth,
        login,
        logout,
        preferences,
        setPreferences,
    };

    // While loading, you may choose to render a spinner or loading screen
    if (loading) return <div>Loading...</div>;

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
