//WebSocketContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import WebSocketService from "./WebSocketService";
import { GlobalContext } from "./GlobalContext";

// Create the context
const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    // Set initial connection status
    const [connectionStatus, setConnectionStatus] = useState(
        WebSocketService.ws ? WebSocketService.ws.readyState : WebSocket.CONNECTING
    );

    // Get the accessToken from your global auth context
    const { auth } = useContext(GlobalContext);
    const { accessToken } = auth;

    // Handler for when the WebSocket opens
    const handleOpen = useCallback(() => {
        setConnectionStatus(WebSocket.OPEN);
        console.log("WebSocket opened");
    }, []);

    // Handler for when the WebSocket closes
    const handleClose = useCallback((event) => {
        const wasClean = event && event.wasClean;
        setConnectionStatus(wasClean ? WebSocket.CLOSED : WebSocket.CONNECTING);
        console.log("WebSocket closed", event);
    }, []);

    // Handler for errors
    const handleError = useCallback((error) => {
        console.error("WebSocket encountered error:", error);
    }, []);

    // Subscribe/unsubscribe from WebSocketService events
    useEffect(() => {
        WebSocketService.on("open", handleOpen);
        WebSocketService.on("close", handleClose);
        WebSocketService.on("error", handleError);

        return () => {
            WebSocketService.off("open", handleOpen);
            WebSocketService.off("close", handleClose);
            WebSocketService.off("error", handleError);
        };
    }, [handleOpen, handleClose, handleError]);

    // Allow components to send messages via the service
    const sendMessage = useCallback((message) => {
        WebSocketService.sendMessage(message);
    }, []);

    // NEW: When the connection is open (or when the token updates),
    // send the authentication message with the accessToken.
    useEffect(() => {
        if (connectionStatus === WebSocket.OPEN && accessToken) {
            WebSocketService.sendMessage({ type: "authenticate", token: accessToken });
        }
    }, [connectionStatus, accessToken]);

    const value = {
        connectionStatus,
        sendMessage,
    };

    return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};
