import { EventEmitter } from "events";

// Singleton wrapper for a WebSocket connection
class WebSocketService extends EventEmitter {
    static instance = null;

    static getInstance() {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    constructor() {
        super();
        // If a singleton instance already exists, return it
        if (WebSocketService.instance) {
            return WebSocketService.instance;
        }

        this.ws = null;
        this.reconnectAttempts = 0;
        this.maxReconnectDelay = 30000; // 30 seconds
        this.heartbeatInterval = null;
        this.heartbeatIntervalTime = 30000; // 30 seconds

        // Bind methods for clarity
        this.handleReconnect = this.handleReconnect.bind(this);
        this.connectWebSocket = this.connectWebSocket.bind(this);
        this.initializeConnection();

        // Save this as the singleton instance
        WebSocketService.instance = this;
    }

    // Initial call to set cookie and then connect
    initializeConnection() {
        this.initializeCookieAndConnect();
    }

    // Example route to set a cookie before connecting
    initializeCookieAndConnect() {
        fetch(`${process.env.REACT_APP_API_URL}/set-cookie`, {
            method: "GET",
            credentials: "include",
        })
            .then(() => {
                this.connectWebSocket();
            })
            .catch((error) => {
                console.error("Error setting cookie:", error);
                this.handleReconnect();
            });
    }

    connectWebSocket() {
        // If we already have a ws connection that is OPEN or CONNECTING, don't reconnect
        if (this.ws) {
            if (
                this.ws.readyState === WebSocket.OPEN ||
                this.ws.readyState === WebSocket.CONNECTING
            ) {
                return;
            }
            this.ws = null;
        }

        // Create the new WebSocket instance
        this.ws = new WebSocket(process.env.REACT_APP_WS_URL);

        // Handle open
        this.ws.onopen = () => {
            this.reconnectAttempts = 0;
            this.startHeartbeat();
            this.emit("open");
            console.log("WebSocket connected");
        };

        // Handle errors
        this.ws.onerror = (error) => {
            console.error("WebSocket error:", error);
            this.emit("error", error);
        };

        // Handle close
        this.ws.onclose = (event) => {
            this.stopHeartbeat();
            this.ws = null;
            if (!event.wasClean) {
                this.handleReconnect();
            }
            this.emit("close", event);
            console.log("WebSocket closed", event);
        };

        // Handle messages
        this.ws.onmessage = (event) => {
            if (!event.data) return;

            try {
                const messageObj = JSON.parse(event.data);

                // Always emit the generic "message" event
                this.emit("message", messageObj);

                // If there's a target property, emit "message:target"
                if (messageObj.target) {
                    this.emit("message:target", messageObj);
                }
                // You could also emit more specific events if you’d like:
                // if (messageObj.conversationDetails)  this.emit("message:conversationDetails", messageObj);
                // if (messageObj.title)                this.emit("message:title", messageObj);
                // if (messageObj.preferences)          this.emit("message:preferences", messageObj);
                // if (messageObj.userType)            this.emit("message:userType", messageObj);
                // if (messageObj.username)            this.emit("message:username", messageObj);
            } catch (error) {
                console.error("Error parsing message:", error);
            }
        };
    }

    handleReconnect() {
        this.reconnectAttempts += 1;
        const backoffDelay = Math.min(
            1000 * 2 ** this.reconnectAttempts,
            this.maxReconnectDelay
        );
        console.log(`Attempting to reconnect in ${backoffDelay / 1000} seconds`);

        setTimeout(() => {
            this.initializeCookieAndConnect();
        }, backoffDelay);
    }

    startHeartbeat() {
        this.stopHeartbeat(); // clear any existing interval
        this.heartbeatInterval = setInterval(() => {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                const pingMessage = JSON.stringify({ type: "ping" });
                this.ws.send(pingMessage);
            }
        }, this.heartbeatIntervalTime);
    }

    stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
    }

    sendMessage(message) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(message));
        } else {
            console.warn("WebSocket is not open. Unable to send message:", message);
        }
    }

    closeWebSocket() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }
}

// Export the singleton instance
export default WebSocketService.getInstance();
